import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Nacelnik = () => {
  return (
    <>
      <Seo title='Načelnik biografija' />
      <Layout>
        <article className='lista'>
          <StaticImage
            src='../images/Marinko_Zic.jpg'
            alt='Marinko Žic'
            layout='constrained'
            placeholder='blur'
            quality='100'
            objectFit='contain'
            objectPosition='0 0'
            aspectRatio='1'
            imgClassName='kandidat-slika'
          />
          <div className='bio'>
            <h2>Marinko Žic</h2>
            <h3>
              kandidat za načelnika Općine Punat i nositelj liste koalicije
              SDP-PGS-HSU
            </h3>
            <p>
              Rođen sam 30. rujna 1971. godine u Rijeci, a živim u svom rodnom
              Puntu.
            </p>
            <p>
              Po zvanju hotelijersko-turistički tehničar, po zavr&scaron;etku
              &scaron;kolovanja pa do izbora za načelnika, radio sam u struci 20
              godina, u prvoj privatnoj turističkoj agenciji na otoku Krku.
            </p>
            <p>
              Na lokalnim izborima 2013. dobivam povjerenje birača i postajem
              načelnik Općine Punat, a 2017. godine potvrđujem svoj status u
              drugom mandatu.
              <br />
              Predsjednik sam Općinske organizacije SDP-a Punta od 2013., a
              svojim postignućem smatram ponovnu aktivaciju lokalne organizacije
              s uključenjem novih članova te uspje&scaron;nu motivaciju
              članstva. Član sam Županijskog odbora SDP PGŽ.
            </p>
            <p>
              Ponosim se svojim antifa&scaron;ističkim uvjerenjem i
              sudjelovanjem u Domovinskom ratu te sam član Udruge
              antifa&scaron;ističkih boraca i antifa&scaron;ista otoka Krka i
              Udruge veterana domovinskog rata otoka Krka.
            </p>
            <p>
              Kroz godine sam sudjelovao u radu i aktivnostima mnogih udruga, a
              zbog mnogobrojnih obaveza, aktivno sam uključen u rad Kumpanije
              &Scaron;torije i Wakeboard kluba.
            </p>
            <h3>&Scaron;TO ME MOTIVIRA?</h3>
            <p>
              Prvenstveno, za novi mandat me kandidirala moja stranka SDP,
              koalicijski partner PGS, ljudi s na&scaron;e liste za Općinsko
              vijeće kao i brojni sumje&scaron;tani koji me podržavaju i žele da
              zavr&scaron;im započeto. A za ono &scaron;to smo planirali,
              potreban nam je jo&scaron; jedan mandat.
            </p>
            <p>
              Kad podvučem crtu svog dosada&scaron;njeg upravljanja općinom od
              niti 2000 stanovnika, mogu svakoga pogledati u lice, čista obraza,
              i s ponosom se osvrnuti na skoro 100 mil. kuna investicija, od
              kojih su &scaron;kola i dvorana doslovno generacijski projekt,
              rije&scaron;enim infrastrukturnim i komunalnim projektima te
              uspje&scaron;nim provođenjem komunalnog reda i urbane opreme, a
              sve s ciljem povećanja kvalitete života na&scaron;ih stanovnika,
              ali i privlačenja novih dokazujući da je Punat ugodno mjesto za
              život, kao i za odmor i uživanje na&scaron;ih gostiju.
            </p>
            <p>
              Primarni interesi mog političkog djelovanja su socijala, odgoj i
              obrazovanje i lokalni razvoj,
            </p>
            <p>
              želim promicati i zauzimati se za opće dobro, a da zakonodavstvo
              bude temeljeno na socijalnoj i dru&scaron;tvenoj pravednosti. U
              sklopu svojih ovlasti i zakonskih mogućnosti, sve svoje životne
              vrijednosti svakodnevno se trudim komunicirati i ugrađivati u
              svoju lokalnu zajednicu.
            </p>
          </div>
        </article>
      </Layout>
    </>
  )
}

export default Nacelnik
